require('./bootstrap');

import '@babel/polyfill'

import Vue from 'vue'
import vuetify from './plugins/vuetify';
import shareLink from "easy-social-share-links"

import "../sass/app.scss";
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss';

const { detect } = require('detect-browser');

Vue.config.productionTip = false

const app = new Vue({
    el: '#app',
    vuetify,
    data: function() {
        return {
            mobileDrawer: false,
            last_url: null,
            providerType: "DR.",
            showPaypal: false
        }
    },
    methods: {
        'maybeLoadPage': function(url) {
            if (this.last_url == url) {
                document.location = url;
                this.last_url = null;
            } else {
                this.last_url = url;
            }
        },
        'shareLink': function(network, page) {
            return shareLink(network, page);
        }
    },
    created: function() {
        //ie doesn't support svg clipping path on html elements... only within svg images themselves.
        //safari flakily supports embedded images into SVG :-/ So .. we are going to switch out to svgs for the ie browsers. 
        var browser = detect();
        console.log(browser.name);
        if (browser.name == 'edge' || browser.name == 'ie') {
            var elements = document.getElementsByClassName('clip-svg-wave');
            for (var i = 0; i < elements.length; i++) {
                elements[i].src = elements[i].src.replace('.jpg', '.svg');
            }
        }
    },
    components: {

    }
})


/*
import Vue from 'vue'

import vuetify from './plugins/vuetify';
import "../sass/app.scss";
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';

Vue.config.productionTip = false


const app = new Vue({
  el: '#app',
  vuetify,
  data : {
    counter: 0,
  },
  methods : {
    'add' : function(amt){
      this.counter = this.counter + amt;
    }
  }
})//.$mount('#app')
*/
